<template>
  <div>
    <h2 class="pageTitle pt-5 mb-8">デフォルト予診票{{ this.newOrEdit }}</h2>
    <div class="mt-12 pt-12 text-center">
      <h3>予診票の{{ this.newOrEdit }}が完了しました</h3>
      <v-btn
        class="mt-12"
        color="primary"
        @click="$router.push({ name: 'allPrediction' })"
        >予診票一覧へ移動</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    newOrEdit: "作成",
  }),
  created() {
    if (this.$router.currentRoute.query.edit) {
      this.newOrEdit = "更新";
    }
  },
  methods: {},
  watch: {},
  filters: {},
};
</script>
<style>
.pageTitle {
  border-bottom: solid 3px rgba(0, 0, 0, 0.87);
}
</style>
